// export default function Root(props) {
//   return <section>{props.name} is mounted!</section>;

// }

import "./price-accuracy.css";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactTooltip from "react-tooltip";

export default function Root(props) {
  const [siteId, setSiteId] = useState("");
  const [opisPrices, setOpisPrices] = useState([]);
  const [ownPrices, setOwnPrices] = useState([]);
  const [latestTransactionPrices, setTxnPrices] = useState([]);
  const [acknowledgementPrices, setAcknowledgementPrice] = useState([]);
  // const [ackPrices, setAckPrices] = useState([]);
  const [status, setStatus] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [iconColor, setIconColor] = useState("acc-icon-grey");
  // console.log("props --->", props);
  useEffect(() => {
    setIsLoading(true);
    props.compRef.mapLayerService.openPriceCheckWindow$.subscribe((data) => {
      //console.log("data -->", data);

      if (data.storeDetails && data.storeDetails.storeId) {
        setIsLoading(true);
        // console.log(data.storeId);
        setSiteId(data.storeDetails.storeId);
        let values = JSON.parse(JSON.stringify(data.result));
        //props.compRef.pricingStateService
          //.getPriceAccuracy(data.storeId)
         // .subscribe((values) => {
            if (values.accuracyIssueFlag !== null) {
              setIconColor(
                values.accuracyIssueFlag ? "acc-icon-red" : "acc-icon-green"
              );
            }
            setIsLoading(false);
            //console.log("values -->", values);
            values.opisPrices.unshift({ sourceName: "OPIS" });
            values.ownPrices.unshift({ sourceName: "OWN" });
            if (values.latestTransactionPrices.length == 0) {
              values.latestTransactionPrices.unshift({
                productName: "",
                retail: "",
              });
            }
            values.latestTransactionPrices.unshift({ sourceName: "TXN" });
            values.latestTransactionPrices.push({
              productName: "",
              retail: "",
            });
            values.latestTransactionPrices.push({
              productName: "",
              retail: "",
            });
            values.latestTransactionPrices.push({
              productName: "",
              retail: "",
            });
            values.latestTransactionPrices.push({
              productName: "",
              retail: "",
            });

            values.acknowledgementPrice.unshift({ sourceName: "ACK" });
            values.acknowledgementPrice.push({
              productName: "",
              retail: "",
            });
            values.acknowledgementPrice.push({
              productName: "",
              retail: "",
            });
            values.acknowledgementPrice.push({
              productName: "",
              retail: "",
            });
            values.acknowledgementPrice.push({
              productName: "",
              retail: "",
            });

            values.opisPrices.push({
              exported: {
                staleHours: null,
                timestamp: "",
              },
              effective: {
                staleHours: null,
                timestamp: null,
              },
              productCode: null,
              productName: "",
              retail: null,
            });
            values.opisPrices.push({
              exported: {
                staleHours: null,
                timestamp: "",
              },
              effective: {
                staleHours: null,
                timestamp: null,
              },
              productCode: null,
              productName: "",
              retail: null,
            });
            values.opisPrices.push({
              exported: {
                staleHours: null,
                timestamp: "",
              },
              effective: {
                staleHours: null,
                timestamp: null,
              },
              productCode: null,
              productName: "",
              retail: null,
            });
            values.opisPrices.push({
              exported: {
                staleHours: null,
                timestamp: "",
              },
              effective: {
                staleHours: null,
                timestamp: null,
              },
              productCode: null,
              productName: "",
              retail: null,
            });
            // values.ackPrices.unshift({ sourceName: "ACK" });
            setTxnPrices(values.latestTransactionPrices);
            setOpisPrices(values.opisPrices);
            values.ownPrices.push({
              exported: {
                staleHours: null,
                timestamp: "",
              },
              effective: {
                staleHours: null,
                timestamp: null,
              },
              productCode: null,
              productName: "",
              retail: null,
            });
            values.ownPrices.push({
              exported: {
                staleHours: null,
                timestamp: "",
              },
              effective: {
                staleHours: null,
                timestamp: null,
              },
              productCode: null,
              productName: "",
              retail: null,
            });
            values.ownPrices.push({
              exported: {
                staleHours: null,
                timestamp: "",
              },
              effective: {
                staleHours: null,
                timestamp: null,
              },
              productCode: null,
              productName: "",
              retail: null,
            });
            values.ownPrices.push({
              exported: {
                staleHours: null,
                timestamp: "",
              },
              effective: {
                staleHours: null,
                timestamp: null,
              },
              productCode: null,
              productName: "",
              retail: null,
            });
            values.ownPrices.push({
              exported: {
                staleHours: null,
                timestamp: "",
              },
              effective: {
                staleHours: null,
                timestamp: null,
              },
              productCode: null,
              productName: "Diesel",
              retail: null,
            });
            setOwnPrices(values.ownPrices);
            setAcknowledgementPrice(values.acknowledgementPrice);
         // });
      }
    });
  }, []);

  const getTransactionStaleHours = (val) => {
    if (!val) {
      return "";
    }

    return getClassName(val.elapsedHours);
  };

  const getClassName = (val) => {
    if (!val) {
      return "";
    }
    if (val < 5) {
      return "price-green-acc";
    }
    if (val >= 5 && val < 10) {
      return "price-blue-acc";
    }
    if (val >= 10 && val < 15) {
      return "price-orange-acc";
    }
    if (val >= 15) {
      return "price-red-acc";
    }

    return "";
  };

  const getTransactionHourClassName = (val) => {
    if (!val) {
      return "";
    }
    return getHourClassName(val.elapsedHours);
  };

  const getHourClassName = (val) => {
    if (!val) {
      return "";
    }
    if (val < 5) {
      return "price-green-acc-txt";
    }
    if (val >= 5 && val < 10) {
      return "price-blue-acc-txt";
    }
    if (val >= 10 && val < 15) {
      return "price-orange-acc-txt";
    }
    if (val >= 15) {
      return "price-red-acc-txt";
    }

    return "";
  };

  const getTransactionEffectiveDaateFormat = (dat) => {
    if (!dat) {
      return "";
    }
    return getEffectiveDateFormat(dat.timestamp);
  };

  const getEffectiveDateFormat = (dat) => {
    // console.log("dat --> ", dat);
    if (dat) {
      const timestamp = new Date(dat);//new Date(dat.replace(" ", "T") + "Z");
      const dd = timestamp.getDate();
      const mm = timestamp.getMonth() + 1;
      const yyyy = timestamp.getFullYear();
      const mi = timestamp.getMinutes();
      const hh = timestamp.getHours();
      const fulldate =
        (mm < 10 ? "0" + mm : mm) +
        "/" +
        (dd < 10 ? "0" + dd : dd) +
        "/" +
        yyyy +
        " @" +
        (hh < 10 ? "0" + hh : hh) +
        ":" +
        (mi < 10 ? "0" + mi : mi);
      return fulldate;
    } else {
      return "";
    }
  };

  const formatPriceDetails = (data) => {
    //console.log("data formatPriceDetails--->", data);
    if (!data) {
      return ["N/A", "", "", ""];
    }
    if(data.toString().includes('.')) {
    const baseSplit = data?.toString()?.split(".");
    const decimalSplit = baseSplit[1]?.toString()?.split("");
    const priceBase = "$" + baseSplit[0];
    const priceTenth = "." + decimalSplit?.[0] ?? 0;
    const priceHundred = decimalSplit?.[1] ?? 0;
    const priceThousanth = decimalSplit?.[2] ?? 0;
    return [priceBase, priceTenth, priceHundred, priceThousanth];
    } else {
      const priceBase = "$" + data.toString();
    const priceTenth = "." +  0;
    const priceHundred =  0;
    const priceThousanth = 0;
    return [priceBase, priceTenth, priceHundred, priceThousanth];
    }
  };

  return (
    <div>
      <div className="user-icon-error-acc">
        <i className={`fas fa-2x fa-money-bill-alt animate ${iconColor}`}></i>
      </div>
      <div className="error-menu-price-acc open">
        <div className="error-title-container-acc">
          <p className="error-title-acc">
            Price Accuracy - {siteId}
            <span className="closeContainer">
              {/* <i
                className="far fa-dollar-sign plc-acc"
                onClick={(e) => {
                  props.compRef.pricingStateService.loadmapFilter$.next(true);
                }}
              ></i>

              <i
                className="far fa-dollar-sign plc-acc"
                onClick={(e) => {
                  props.compRef.pricingStateService.loadmapStates$.next(true);
                }}
              ></i>

              <i
                className="far fa-dollar-sign plc-acc"
                onClick={(e) => {
                  setStatus(!status);
                }}
              ></i> */}

              <i className="fas fa-2x fa-times-circle headingContainerIcon"></i>
            </span>
          </p>
        </div>
        {status && (
          <div className="lagOuterContainer">
            <div className="colorStatusContainer">
              <div className="colorStatusContainerItem">
                <span className="colorStatusContainerItemStausPush">
                  <i className="fas fa-file-import"></i> Push to POS
                  <span className="colorStatusContainerItemStaus2"> 2</span>
                </span>
                <div className="meter nostripes priceBlink">
                  <span className="colorStatusContainerItemStausBar"></span>
                </div>
              </div>
              <div className="colorStatusContainerItem">
                <span className="colorStatusContainerItemStausACK">
                  <i className="fas fa-vote-yea"></i> Ack From POS
                  <span className="colorStatusContainerItemStaus3"> 3</span>
                </span>
                <div className="statusFlex">
                  <div className="meter red nostripes">
                    <span className="colorStatusContainerItemStausBar"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {!isloading && (
          <div className="lagOuterContainer-acc">
            <div className="lagNoDataContainer-acc">
              <div className="transactionBody-acc ">
                {ownPrices.map((ownPrice, i) => {
                  // console.log("Entered");
                  if (i == 0) {
                    return (
                      <div key={i} className="order-one source-name">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div>
                            <div
                              className="fuel-price-txn-grey-acc"
                              style={{ backgroundColor: "white" }}
                            >
                              {ownPrice.sourceName}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (i == 1) {
                    return (
                      <div key={i} className="order-two ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {ownPrice.productName}
                          </div>

                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                ownPrice.effective
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(ownPrice.retail)[0]}
                                {formatPriceDetails(ownPrice.retail)[1]}
                                {formatPriceDetails(ownPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(ownPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            data-tip
                            data-class="testToolTip"
                            data-for={`registerTip-ownPrice${i}`}
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              ownPrice.effective
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              ownPrice.effective
                            )}
                          </div>

                          {ownPrice.exported && ownPrice.exported.timestamp && (
                            <ReactTooltip
                              id={`registerTip-ownPrice${i}`}
                              place="top"
                              effect="solid"
                            >
                              EX:{" "}
                              {getTransactionEffectiveDaateFormat(
                                ownPrice.exported
                              )}
                            </ReactTooltip>
                          )}

                          {/* <div className="tooltip-acc">
                          <span>here</span>
                          <i  className="fas fa-2x  fa-money-bill-alt" id="price-check-badge"></i>
                        
                          <span className="tooltiptext-acc">
                          {getEffectiveDateFormat(ownPrice.effective.timestamp)} 
                          {getEffectiveDateFormat(ownPrice.exported.timestamp)}
                          </span>
                        </div> */}
                        </div>
                      </div>
                    );
                  }

                  if (i == 2) {
                    return (
                      <div key={i} className="order-three ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {ownPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                ownPrice.effective
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(ownPrice.retail)[0]}
                                {formatPriceDetails(ownPrice.retail)[1]}
                                {formatPriceDetails(ownPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(ownPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            data-tip
                            data-class="testToolTip"
                            data-for={`registerTip-ownPrice${i}`}
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              ownPrice.effective
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              ownPrice.effective
                            )}
                          </div>

                          {ownPrice.exported && ownPrice.exported.timestamp && (
                            <ReactTooltip
                              id={`registerTip-ownPrice${i}`}
                              place="top"
                              effect="solid"
                            >
                              EX:{" "}
                              {getTransactionEffectiveDaateFormat(
                                ownPrice.exported
                              )}
                            </ReactTooltip>
                          )}
                        </div>
                      </div>
                    );
                  }

                  if (i == 3) {
                    return (
                      <div key={i} className="order-four ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {ownPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                ownPrice.effective
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(ownPrice.retail)[0]}
                                {formatPriceDetails(ownPrice.retail)[1]}
                                {formatPriceDetails(ownPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(ownPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            data-tip
                            data-class="testToolTip"
                            data-for={`registerTip-ownPrice${i}`}
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              ownPrice.effective
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              ownPrice.effective
                            )}
                          </div>

                          {ownPrice.exported && ownPrice.exported.timestamp && (
                            <ReactTooltip
                              id={`registerTip-ownPrice${i}`}
                              place="top"
                              effect="solid"
                            >
                              EX:{" "}
                              {getTransactionEffectiveDaateFormat(
                                ownPrice.exported
                              )}
                            </ReactTooltip>
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (i == 4) {
                    return (
                      <div key={i} className="order-five ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {ownPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                ownPrice.effective
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(ownPrice.retail)[0]}
                                {formatPriceDetails(ownPrice.retail)[1]}
                                {formatPriceDetails(ownPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(ownPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            data-tip
                            data-class="testToolTip"
                            data-for={`registerTip-ownPrice${i}`}
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              ownPrice.effective
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              ownPrice.effective
                            )}
                          </div>

                          {ownPrice.exported && ownPrice.exported.timestamp && (
                            <ReactTooltip
                              id={`registerTip-ownPrice${i}`}
                              place="top"
                              effect="solid"
                            >
                              EX:{" "}
                              {getTransactionEffectiveDaateFormat(
                                ownPrice.exported
                              )}
                            </ReactTooltip>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>

              <div className="transactionBody ">
                {opisPrices.map((opisPrice, i) => {
                  // console.log("Entered");
                  if (i == 0) {
                    return (
                      <div key={i} className="order-one source-name">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div>
                            <div
                              className="fuel-price-txn-grey-acc"
                              style={{ backgroundColor: "white" }}
                            >
                              {opisPrice.sourceName}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (i == 1) {
                    return (
                      <div key={i} className="order-two ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {opisPrice.productName}
                          </div>

                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                opisPrice.effective
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(opisPrice.retail)[0]}
                                {formatPriceDetails(opisPrice.retail)[1]}
                                {formatPriceDetails(opisPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(opisPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            data-tip
                            data-class="testToolTip"
                            data-for={`registerTip-opisPrices${i}`}
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              opisPrice.effective
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              opisPrice.effective
                            )}
                          </div>

                          {opisPrice.exported && opisPrice.exported.timestamp && (
                            <ReactTooltip
                              id={`registerTip-opisPrices${i}`}
                              place="top"
                              effect="solid"
                            >
                              EX:{" "}
                              {getTransactionEffectiveDaateFormat(
                                opisPrice.exported
                              )}
                            </ReactTooltip>
                          )}
                        </div>
                      </div>
                    );
                  }

                  if (i == 2) {
                    return (
                      <div key={i} className="order-three ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {opisPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                opisPrice.effective
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(opisPrice.retail)[0]}
                                {formatPriceDetails(opisPrice.retail)[1]}
                                {formatPriceDetails(opisPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(opisPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            data-tip
                            data-class="testToolTip"
                            data-for={`registerTip-opisPrices${i}`}
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              opisPrice.effective
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              opisPrice.effective
                            )}
                          </div>

                          {opisPrice.exported && opisPrice.exported.timestamp && (
                            <ReactTooltip
                              id={`registerTip-opisPrices${i}`}
                              place="top"
                              effect="solid"
                            >
                              EX:{" "}
                              {getTransactionEffectiveDaateFormat(
                                opisPrice.exported
                              )}
                            </ReactTooltip>
                          )}
                        </div>
                      </div>
                    );
                  }

                  if (i == 3) {
                    return (
                      <div key={i} className="order-four ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {opisPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                opisPrice.effective
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(opisPrice.retail)[0]}
                                {formatPriceDetails(opisPrice.retail)[1]}
                                {formatPriceDetails(opisPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(opisPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            data-tip
                            data-class="testToolTip"
                            data-for={`registerTip-opisPrices${i}`}
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              opisPrice.effective
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              opisPrice.effective
                            )}
                          </div>

                          {opisPrice.exported && opisPrice.exported.timestamp && (
                            <ReactTooltip
                              id={`registerTip-opisPrices${i}`}
                              place="top"
                              effect="solid"
                            >
                              EX:
                              {getTransactionEffectiveDaateFormat(
                                opisPrice.exported
                              )}
                            </ReactTooltip>
                          )}
                        </div>
                      </div>
                    );
                  }
                  if (i == 4) {
                    return (
                      <div key={i} className="order-five ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {opisPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                opisPrice.effective
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(opisPrice.retail)[0]}
                                {formatPriceDetails(opisPrice.retail)[1]}
                                {formatPriceDetails(opisPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(opisPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            data-tip
                            data-class="testToolTip"
                            data-for={`registerTip-opisPrices${i}`}
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              opisPrice.effective
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              opisPrice.effective
                            )}
                          </div>

                          {opisPrice.exported && opisPrice.exported.timestamp && (
                            <ReactTooltip
                              id={`registerTip-opisPrices${i}`}
                              place="top"
                              effect="solid"
                            >
                              EX:
                              {getTransactionEffectiveDaateFormat(
                                opisPrice.exported
                              )}
                            </ReactTooltip>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>

              <div className="transactionBody ">
                {latestTransactionPrices.map((txnPrice, i) => {
                  //console.log("Entered");
                  if (i == 0) {
                    return (
                      <div key={i} className="order-one source-name">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div>
                            <div
                              className="fuel-price-txn-grey-acc"
                              style={{ backgroundColor: "white" }}
                            >
                              {txnPrice.sourceName}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (i == 1) {
                    return (
                      <div key={i} className="order-two ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {txnPrice.productName}
                          </div>

                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                txnPrice.latest_txn
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(txnPrice.retail)[0]}
                                {formatPriceDetails(txnPrice.retail)[1]}
                                {formatPriceDetails(txnPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(txnPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              txnPrice.latest_txn
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              txnPrice.latest_txn
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }

                  if (i == 2) {
                    return (
                      <div key={i} className="order-three ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {" "}
                            {txnPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                txnPrice.latest_txn
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(txnPrice.retail)[0]}
                                {formatPriceDetails(txnPrice.retail)[1]}
                                {formatPriceDetails(txnPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(txnPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              txnPrice.latest_txn
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              txnPrice.latest_txn
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }

                  if (i == 3) {
                    return (
                      <div key={i} className="order-four ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {txnPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                txnPrice.latest_txn
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(txnPrice.retail)[0]}
                                {formatPriceDetails(txnPrice.retail)[1]}
                                {formatPriceDetails(txnPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(txnPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              txnPrice.latest_txn
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              txnPrice.latest_txn
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (i == 4) {
                    return (
                      <div key={i} className="order-five ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                            {txnPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                txnPrice.latest_txn
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(txnPrice.retail)[0]}
                                {formatPriceDetails(txnPrice.retail)[1]}
                                {formatPriceDetails(txnPrice.retail)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(txnPrice.retail)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              txnPrice.latest_txn
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(
                              txnPrice.latest_txn
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>

              <div className="transactionBody ">
                {acknowledgementPrices.map((txnPrice, i) => {
                  // console.log("Entered");
                  if (i == 0) {
                    return (
                      <div key={i} className="order-one source-name">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div>
                            <div
                              className="fuel-price-txn-grey-acc"
                              style={{ backgroundColor: "white" }}
                            >
                              {txnPrice.sourceName}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (i == 1) {
                    return (
                      <div key={i} className="order-two ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                          {txnPrice.productName}
                          </div>

                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                txnPrice.price_update_processed
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(txnPrice.price)[0]}
                                {formatPriceDetails(txnPrice.price)[1]}
                                {formatPriceDetails(txnPrice.price)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(txnPrice.price)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              txnPrice.price_update_processed
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(txnPrice.price_update_processed)}
                          </div>
                        </div>
                      </div>
                    );
                  }

                  if (i == 2) {
                    return (
                      <div key={i} className="order-three ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                          {txnPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                txnPrice.price_update_processed
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(txnPrice.price)[0]}
                                {formatPriceDetails(txnPrice.price)[1]}
                                {formatPriceDetails(txnPrice.price)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(txnPrice.price)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              txnPrice.price_update_processed
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(txnPrice.price_update_processed)}
                          </div>
                        </div>
                      </div>
                    );
                  }

                  if (i == 3) {
                    return (
                      <div key={i} className="order-four ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                          {txnPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                txnPrice.price_update_processed
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(txnPrice.price)[0]}
                                {formatPriceDetails(txnPrice.price)[1]}
                                {formatPriceDetails(txnPrice.price)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(txnPrice.price)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              txnPrice.price_update_processed
                            )}`}
                          >
                            {getTransactionEffectiveDaateFormat(txnPrice.price_update_processed)}
                          </div>
                        </div>
                      </div>
                    );
                  }
                  if (i == 4) {
                    return (
                      <div key={i} className="order-five ">
                        <div className="fuel-price-wrapper-status-acc fuel-price-wrapper-status-txn">
                          <div className="fuel-grade fuel-grade-txn-acc">
                          {txnPrice.productName}
                          </div>
                          <div>
                            <div
                              className={`fuel-price-txn-grey-acc ${getTransactionStaleHours(
                                txnPrice.price_update_processed
                              )} `}
                            >
                              <div className="base-acc">
                                {formatPriceDetails(txnPrice.price)[0]}
                                {formatPriceDetails(txnPrice.price)[1]}
                                {formatPriceDetails(txnPrice.price)[2]}
                              </div>
                              <div className="thousanth thousanth-txn">
                                {formatPriceDetails(txnPrice.price)[3]}
                              </div>
                            </div>
                          </div>
                          <div
                            className={`mat-tooltip-trigger fuel-timestamp fuel-timestamp-txn-acc ${getTransactionHourClassName(
                              txnPrice.price_update_processed
                            )}`}
                          >
                           {getTransactionEffectiveDaateFormat(txnPrice.price_update_processed)}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}
              </div>

              <div className="hours-container">
                <ul
                  style={{ paddingLeft: 22 }}
                  className="P8Seg-acc YHW25d-acc "
                >
                  <span className="hours-container-inner">
                    <span className="YHW25d-acc  aJBiQb-acc "></span>
                    <span className="YHW25d-acc hours-text">0 - 5hrs</span>
                  </span>

                  <span className="hours-container-inner">
                    <span className="YHW25d-acc  aJBiQb-acc hours-containerItem-blue"></span>
                    <span className="YHW25d-acc hours-text">5hrs - 10hrs</span>
                  </span>

                  <span className="hours-container-inner">
                    <span className="YHW25d-acc  aJBiQb-acc hours-containerItem-orange"></span>
                    <span className="YHW25d-acc hours-text">10hrs - 15hrs</span>
                  </span>

                  <span className="hours-container-inner">
                    <span className="YHW25d-acc  aJBiQb-acc hours-containerItem"></span>
                    <span className="YHW25d-acc hours-text"> above 15hrs</span>
                  </span>
                </ul>
              </div>
            </div>
          </div>
        )}
        {isloading && <div className="loader-acc">loading... </div>}
      </div>
    </div>
  );
}
